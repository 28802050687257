import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const { env, sentry: sentryConfig } = useConfig()
  const router = useRouter()

  if (!sentryConfig) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryConfig.dsn,
    environment: env,
    release: runtimeConfig.public.version,
    integrations: process.client ?[
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracingOrigins: ["localhost", "boxmagenta.com.br", "homolog.boxmagenta.com.br", "customer-api.boxmagenta.com.br", /^\//],

        // Make sure that the server allows the 'sentry-trace' header before enabling this flags
        traceFetch: false,
        traceXHR: false
      }),
    ] : [],
    tracesSampleRate: 0.2,
  });

  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.error("Vue errorHandler: ", error, context)

    Sentry.captureException(error)
  }

  return {
    provide: {
      sentry: Sentry,
    }
  }
})