
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/pt-br'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'


dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

dayjs.locale('en')
dayjs.locale('pt-br')
dayjs.tz.setDefault('UTC')



export default dayjs
